#deep-learning {
  // background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  .two-col-heading {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    font: normal normal 300 3.125rem/3.9375rem Open Sans;
    letter-spacing: 0.09375rem;
    @media screen and (orientation: landscape) and (max-width: 768px) {
      & {
        padding-top: 0;
      }
    }
    @media only screen and (orientation: portrait) and (max-width: 320px) {
      & {
        font: normal normal 300 2.5rem/3.9375rem Open Sans;
      }
    }
    @media screen and (min-width: 1601px) {
      & {
        padding-top: 4.875rem;
      }
    }
    div {
      flex: 0 0 auto;
    }
    .main-heading {
      word-break: keep-all;
      height: fit-content;
    }
    .heading-flex {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    .heading-options {
      font: normal normal bold 3.125rem/5.9375rem Open Sans;
      letter-spacing: 0.175rem;
      opacity: 0.09;
      position: absolute;
      @media only screen and (orientation: portrait) and (max-width: 320px) {
        & {
          font: normal normal 700 2.5rem/5.9375rem Open Sans;
        }
      }
      &:nth-child(2) {
        top: 4.9375rem;
      }
      &:nth-child(3) {
        top: calc(4.9375rem * 2);
      }
      &:nth-child(4) {
        top: calc(4.9375rem * 3);
      }
      &.bold {
        font: normal normal bold 3.125rem/3.9375rem Open Sans;
        letter-spacing: 0.03125rem;
        opacity: 1;
        @media only screen and (orientation: portrait) and (max-width: 320px) {
          & {
            font: normal normal 700 2.5rem/3.9375rem Open Sans;
          }
        }
      }
    }
  }
  .dot {
    color: $orange;
  }
  .development {
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      & {
        margin: 2rem 0;
      }
    }
    @media screen and (min-width: 1367px) {
      & {
        margin-top: 1rem;
      }
    }
    .heading {
      @media only screen and (orientation: portrait) and (max-width: 767px) {
        & {
          margin: 2rem 0 2rem 0;
        }
      }
      @media only screen and (orientation: portrait) and (max-width: 320px) {
        & {
          margin: 1rem 0 2rem 0;
        }
      }
    }
    .article-header {
      width: 100%;
      font: normal normal 600 1.5rem/2.5rem Open Sans;
      margin-bottom: 1.25rem;
     
      @media screen and (min-width: 768px) {
        width: 75%;
      }
      @media screen and (orientation: landscape) {
        & {
          width: 75%;
        }
      }
      @media screen and (orientation: landscape) and (max-width: 768px) {
        & {
          width: 88%;
        }
      }
      @media only screen and (orientation: portrait) and (max-width: 767px) {
        & {
          font: normal normal 600 2.5rem/4.5rem Open Sans;
          margin-bottom: 0.5rem;
        }
      }
      @media only screen and (orientation: portrait) and (max-width: 320px) {
        & {
          font: normal normal 500 2.5rem/3.5rem Open Sans;
        }
      }
      @media screen and (min-width: 1367px) {
        & {
          margin-top: 3rem;
        }
      }
    }
    .article-text {
      width: 100%;
      @media screen and (orientation: landscape) {
        & {
          width: 75%;
          margin-bottom: 1rem;
        }
      }
      @media screen and (orientation: landscape) and (max-width: 768px) {
        & {
          width: 88%;
          margin-bottom: 0.75rem;
        }
      }
      @media screen and (min-width: 768px) {
        & {
          width: 75%;
        }
      }
      @media only screen and (orientation: portrait) and (max-width: 767px) {
        & {
          font: normal normal 400 2.25rem/3.5rem Open Sans;
          margin-bottom: 2rem;
        }  
      }
      @media only screen and (orientation: portrait) and (max-width: 320px) {
        & {
          font: normal normal 300 2.25rem/3rem Open Sans;
          margin-bottom: 2rem;
        }
      }
      @media only screen and (min-width: 1601px) {
        & {
          width: 44%;
        }
      }
      @media only screen and (min-width: 1920px) {
        & {
          width: 55%;
        }
      }
    }
    
    .article-footer {
      position: relative;
      width: fit-content;
      @media only screen and (orientation: portrait) and (max-width: 767px) {
        & {
          width: 100%;
        }
      }
      .btn {
        height: 3.125rem;
        margin-right: 1.25em;
        width: 12.5rem;
        font: normal normal 600 1.125rem/2.5rem Open Sans;
        @media screen and (min-width: 1601px) {
          margin-top: 1rem;
        }
        &.demo {
          color: $light-blue;
        }
        &.trial {
          border-radius: 6px;
          opacity: 1;
          color: $orange;
        }
        @media only screen and (orientation: portrait) and (max-width: 767px) {
          & {
            margin-top: 0.5rem;
            margin-right: 0.5rem;
            font: normal normal 600 2rem/2.5rem Open Sans;
            width: 20rem;
            height: 6rem;
            max-width: 45%;
            &.demo {
              color: $light-blue;
            }
            &.trial {
              border-radius: 6px;
              opacity: 1;
              color: $orange;
            }
          }
        }
        @media only screen and (orientation: portrait) and (max-width: 320px) {
          & {
            margin-right: 0.5em;
            height: 6rem;
          }
        }
      }
    }
    .animation {
      @media only screen and (orientation: portrait) and (max-width: 767px) {
        & {
          height: auto;
          overflow: visible;
        }
      }
    }
  }
}