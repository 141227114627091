* {
  box-sizing: border-box;
}
html {
  font-size: 97%;
  @media screen and (min-width: 2560px) {
    & {
      font-size: 125%;
    }
  }
  @media screen and (min-width: 3840px) {
    & {
      font-size: 200%;
    }
  }
  @media screen and (orientation: landscape) and (max-height: 1024px) and (max-width: 1367px) {
    & {
      font-size: 80%;
    }
  }

  @media screen and (orientation: landscape) and (min-width: 768px) and (max-width: 1023px) {
    & {
      font-size: 50%;
    }
  }
  @media screen and (orientation: landscape) and (max-width: 768px) {
    & {
      font-size: 50%;
    }
  }

  @media screen and (orientation: portrait) and (max-width: 767px) {
    & {
      font-size: 47%;
    }
  }
}

.paddings {
  padding: calc(#{$p-height} / 2) calc(#{$p-width} / 3) calc(#{$py} / 8) calc(#{$p-width} / 8);
  &.p-reduced {
    padding: calc(#{$p-height} / 4) calc(#{$p-width} / 4) calc(#{$py} / 8) ;
    @media only screen and (min-width: 1200px) {
      & {
        padding: $p-height $p-width calc(#{$py} / 2) calc(#{$p-width} / 1.25);
      }
    }
  }
  @media only screen and (min-width: 768px) {
    & {
      padding: calc(#{$p-height} / 2) calc(#{$p-width} / 3) #{$py / 2} calc(#{$p-width} / 4);
    }
  }
  @media only screen and (min-width: 1200px) {
    & {
      padding: calc(#{$p-height}) calc(#{$p-width}) #{$py}
    }
  }
  @media only screen and (min-width: 1601px) {
    & {
      padding: calc(#{$p-height}) calc(#{$p-width}) calc(#{$p-height} / 2)
    }
  }
  @media only screen and (orientation: portrait) and (max-width: 767px) {
    &, &.p-reduced {
      padding-bottom: 5rem;
    }
  }
}

body {
  color: white;
  background-color: $dark-blue;
  // overflow: hidden;
  overflow-x: hidden;
  .plus-container {
    @extend .paddings;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;
    z-index: 0;
    .plus {
      display: flex;
      width: 100%;
      justify-content: space-between;
      div {
        color: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

h1 {
  font: normal normal 300 3.125rem/5.9375rem Open Sans;
}

p {
  font: normal normal 300 1.25rem/2.5rem Open Sans;
  letter-spacing: 0px;
  color: white;
}

.input-style {
  width: 100%;
  height: 3.125rem;
  padding: 0.5rem 0.75rem;
  padding-left: 2rem;
  border-radius: 4px;
  resize: vertical;
  font: normal normal normal 1.125rem/1.125rem Open Sans;
  background: transparent;
  border: none;
  border-bottom: 2px solid $grey;
  transition: border 0.5s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
  margin-top: 0.75rem;
  outline: none;
  color: #fff;
  &:focus,
  &:focus {
    outline: none;
    border-bottom: 2px solid $light-blue;
  }
  @media screen and (min-width: 1601px) {
    height: 3.5rem;
    font-size: 1rem;
    margin-top: 1rem;
  }
  @media screen and (max-width: 600px) {
    height: 2.75rem;
    font-size: 1rem;
    padding-left: 1.5rem;
    margin-top: 0.75rem;
  }
  @media only screen and (orientation: landscape) and (max-width: 767px) {
    margin-top: 0.5rem;
  }
  @media only screen and (orientation: portrait) and (max-width: 767px) {
    & {
      height: 5.75rem;
      font-size: 2rem;
      padding-left: 2.25rem;
      margin-top: 1.75rem;
    }
  }
}
.input-img {
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 3rem;
  @media screen and (max-width: 600px) {
    height: 2.75rem;
  }
  @media only screen and (orientation: portrait) and (max-width: 767px) {
    & {
      height: 4.75rem;
    }
  }
}
.form-error {
  font: normal normal 300 1rem/1.125rem Open Sans;
  display: none;
  color: red;
  position: absolute;
  bottom: 0.125rem;
  left: 0.125rem;
  user-select: none;
  @media only screen and (max-width: 812px) {
    & {
      font: normal normal 300 1.325rem/1.325rem Open Sans;
    }
  }
}

.check-mark, .error {
  display: none;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  font: normal normal 300 1.125rem/1.5rem Open Sans;
  @media only screen and (max-width: 812px) {
    & {
      font: normal normal 300 1.625rem/1.625rem Open Sans;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.error {
  a {
    @media only screen and (min-width: 812px) {
      & {
      padding-left: 0.5rem;
    }
  }
    color: #fff;
  }
  color: red;
}
.live-demo-modal {
  cursor:default;
  display:none;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position:fixed;
  margin: auto;
  z-index:999;
  cursor: pointer;
  .article-header {
    font: normal normal normal 1rem/1.5rem Open Sans;
    margin-bottom: 0;
  }
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left:0;
    top: 0;
    background-color: rgba(0,0,0,.2);
    z-index: 1;
  }
  .email-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-color:$dark-blue;
    border: 1px solid $grey;
    border-radius: 5px;
    max-height: 25rem;
    max-width: 100vw;
    min-width: 29rem;
    padding: 25px 25px 20px;
    cursor: auto;
    z-index: 2;
    transition: width 1s ease-in-out;
    .close-dialog {
      color: $grey;
      font-size: 1.125rem;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: #fff;
        transform: scale(1.1);
      }
    }
    .row {
      width: auto;
      position: relative;
      margin: 1rem 0 1.5rem 2rem;
    }
    .submit-btn {
      align-self: flex-end;
    }
    .btn {
      font: normal normal normal 1rem/2rem Open Sans;
      height: 2.5rem;
      color: $light-blue;
      min-width: 5rem;
    }
  }
}
#wrapper {
  display: grid;
  // grid-template-columns: auto;
  // grid-template-rows: auto;
  grid-template-areas:
      "learning"
      "slider"
      "benefits"
      "management"
      "dashboards"
      "analysis"
      "optimization"
      "idea"
      "identify"
      "understand"
      "maximize"
      "pricing"
      "signup";
  height: auto;
  background-repeat: repeat;
  background-attachment: initial;
  background-origin: padding-box;
  background-clip: padding-box;
  background-color: transparent;
  background-image: url("../img/bg.svg");
  @media only screen and (orientation: portrait) and (max-width: 768px) {
    & {
      background-repeat: repeat;
    }
  }
  @media only screen and (min-width: 1024px) {
    & {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto;
    }
  }
  @media screen and (min-width: 3840px) {
    & {
      background-size: 100%;
    }
  }
}

.content {
  @extend .paddings;
  width: 100vw;
  max-height: 100vh;
  &.fullscreen {
    height: 100vh;
    @media only screen and (orientation: portrait) {
      &:not(.main) {
        height: max-content;
      }
    }
  }
  overflow: hidden;
  z-index: 1;
}

.article-header {
  font: normal normal 300 1.875rem/2.5rem Open Sans;
  letter-spacing: 0px;
  color: $light-blue;
  a, a:visited {
    color: inherit;
  }
  //   margin-bottom: 1.4rem;
  @media only screen and (min-width: 768px) {
    & {
      margin-bottom: 2.8125rem;
    }
  }
}

.article-text {
  font: normal normal 300 1.25rem/2.5rem Open Sans;
  letter-spacing: 0px;
  a, a:visited {
    color: inherit;
  }
  //   margin-bottom: 1.4rem;
  @media only screen and (min-width: 768px) {
    & {
      margin-bottom: 2.8125rem;
    }
  }
}

.text-no-wrap {
  white-space: nowrap;
}

#deep-empty {
  z-index: -1;
}

#management {
  grid-area: management;
}
#dashboards {
  grid-area: dashboards;
}
#analysis {
  grid-area: analysis;
}
#optimization {
  grid-area: optimization;
}
#deep-learning {
  grid-area: learning;
}

#pricing {
  grid-area: pricing;
}

#empty {
  grid-area: empty;
}

#signup {
  grid-area: signup;
}

#benefits {
  grid-area: benefits;
}

#slider-info {
  grid-area: slider;
}