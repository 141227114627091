.btn {
  display: inline-block;
  background: transparent;
  border: 2px solid $grey;
  border-radius: 5px;
  margin: 0;
  height: 3.125rem;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease, border 0.5s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  font: normal normal 300 1.875rem/2.5rem Open Sans;
  color: #fff;
}

.btn:hover,
.btn:focus {
  outline: none;
  border-color: #fff;
}

.btn:active {
  transform: scale(1);
}
