#termsOfUse.wrapper, #privacyPolicy.wrapper {
    a, a:visited {
        color: white;
    }
    padding: 0 4rem;
    h1 {
        text-align: center;
        font: normal normal 300 3.125rem/3.9375rem Open Sans;
    }
    .revisited {
        text-align: center;
        margin-bottom: 2rem;
    }
    ul, ol {
        list-style: inside;
        margin: 0 2rem;
        font: normal normal normal 1rem/1.5rem Open Sans;
    }
    .section-header {
        font: normal normal bold 1.5rem/3.5rem Open Sans;
    }
    .section-paragraph {
        font: normal normal normal 1rem/1.5rem Open Sans;
        margin-bottom: 0.5rem;
    }
    .use {
        .use-ul {
            list-style: inside;
            list-style-type: lower-roman;
        }
    }
    @media screen and (min-width: 1024px) {
        & {
            width: 70%;
            margin: 0 auto;
        }
    }

}
#privacyPolicy.wrapper {
    h1 {
        font: normal normal 300 3.125rem/3.9375rem Open Sans;
        text-align: center;
    }
    .revisited {
        text-align: center;
        margin-bottom: 2rem;
    }
    .collect-info {
        list-style-type: upper-alpha;
        list-style-position: inside;
    }
    .inner-collect-info {
        list-style-type: lower-alpha;
        list-style-position: inside;
    }
    .indented {
        padding: 0 2rem;
    }
    h3.section-header {
        display: inline;
    }
    h2.section-header {
        text-decoration: underline;
    }
    .underline {
        text-decoration: underline;
    }
    p.inline {
        display: inline;
    }
    td, th {
        border: 1px solid #dddddd;
        padding: 0.5rem;
    }
    ul.circle {
        list-style-type: circle;
    }
    .cookies {
        .section-header {
            text-decoration: none;
        }
    }
}