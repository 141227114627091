#benefits {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: $py;
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    & {
      justify-content: flex-start;
    }
  }
  @media screen and (min-width: 1200px) {
    & {
      padding-right: calc(#{$p-width} + 3rem);
    }
  }
  .idea-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (orientation: portrait) {
      & {
        flex-direction: column;
        .features {
          width: 100%;
        }
      }
    }
    .description {
      display: flex;
      flex-direction: column;
      width: 50%;
      max-width: 100%;
      min-width: 42rem;
      margin: 0 0.5rem;
      text-align: center;
      align-items: center;
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        & {
          flex: 1 0 50%;
        }
      }
      @media only screen and (orientation: landscape) and (min-width: 1200px) {
        & {
          min-width: 51rem;
        }
      }
      @media only screen and (min-width: 3840px) {
        & {
          flex: 0 0 40%;
        }
      }
      .article-header {
        font: normal normal bold 3.125rem/4.1875rem Open Sans;
        letter-spacing: 0.5px;
        margin-bottom: 2rem;
      }
      .article-text {
        margin-bottom: $py;
        &.reduced {
          width: 35rem;
        }
        font: normal normal normal 1.125rem/1.875rem Open Sans;
        @media only screen and (max-width: 812px) {
          & {
            font: normal normal normal 1.5rem/2.5rem Open Sans;
          }
        }
        @media only screen and (orientation: landscape) and (max-width: 812px) {
          & {
            margin-bottom: 2.5rem;
            font: normal normal normal 1.5rem/2.5rem Open Sans;
          }
        }
        @media only screen and (min-width: 3840px) {
          & {
            font: normal normal normal 1.5rem/2.5rem Open Sans;
          }
        } 
      }
    }
    .features {
      display: flex;
      max-width: 70rem;
      width: 65vw;
      margin-right: auto;
      margin-left: auto;
      margin: auto 0.5rem;
      margin-top: 7.5rem;
      @media only screen and (orientation: portrait) {
        width: 100%;
      }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        & {
          height: fit-content;
          margin: 0;
        }
      }
      .article-text {
        margin-bottom: 0;
      }
      .features-wrapper {
        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: space-between;
        @media only screen and (min-width: 1600px) {
          & {
            // height: 80%;
            justify-content: space-between;
          }
        }
        @media only screen and (min-width: 3840px) {
          & {
            justify-content: center;
          }
        }
        .feature {
          display: flex;
          flex-direction: column;
          align-items: center;
          align-content: center;
          text-align: center;
          margin-bottom: 1rem;
          margin-left: 1rem;
          margin-right: 1rem;
          max-width: 13.5rem;
          height: 8.125rem;
          @media only screen and (min-width: 1200px) {
            & {
              margin-left: 2rem;
              margin-right: 2rem;
            }
          }
          figure {
            flex: 1 0 7rem;
            @media only screen and (orientation: landscape) and (max-width: 812px) {
              & {
                flex: 1 0 5rem;
              }
            }
            img {
              max-width: 5.375rem;
              width: 5.375rem;
              max-height: 3.8125rem;
            }
          }
          p {
            font: normal normal normal 1.125rem/1.5rem Open Sans;
            letter-spacing: 0.18px;
            @media only screen and (orientation: portrait) and (max-width: 767px) {
              & {
                font: normal normal normal 1.5rem/2.5rem Open Sans;
              }
            }
            @media only screen and (orientation: landscape) and (max-width: 767px) {
              & {
                font: normal normal normal 1.375rem/1.5rem Open Sans;
              }
            }
            @media only screen and (min-width: 3840px) {
              & {
                font: normal normal normal 1.25rem/2rem Open Sans;
              }
            } 
          }
        }
      }
    }
  }
}