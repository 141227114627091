.vertical-line-wrapper {
  width: 1rem;
  z-index: 3;
  .container {
    position: relative;
  }
  .vertical-line {
    position: absolute;
    height: 18rem;
    width: 0.1875rem;
    background: #fff;
    opacity: 0.1;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .scroller {
    height: 5.625rem;
    width: 0.375rem;
    background: #fff;
    opacity: 0.2;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.animation-elements {
  .vertical-line-wrapper {
    position: fixed;
    right: 1rem;
    top: 1rem;
    height: 100vh;
  }
}

.two-lines {
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4rem;
  .vertical-line-wrapper {
    display: block;
    height: 100%;
    height: 15rem;
    margin: 2rem 0px;
    .container {
      height: 15rem;
      .vertical-line {
        height: 15rem;
        width: 0.05rem;
        opacity: 0.3;
      }
      .scroller {
        height: 6.25rem;
        width: 0.275rem;
        opacity: 0.3;
        left: 0.325rem;
        &.bottom {
          bottom: 0;
        }
      }
    }
    @media only screen and (orientation: landscape) and (max-width: 768px) {
      & {
        height: 7.5rem;
        .container {
          height: 7.5rem;
          .vertical-line {
            height: 7.5rem;
          }
          .scroller {
            height: 3.25rem;
          }
        }
      }
    }
    
  }
  @media only screen and (orientation: portrait) and (max-width: 767px) {
    & {
        left: 0.5rem;
        .vertical-line-wrapper {
            height: 25rem;
            .container {
              height: 25rem;
              .vertical-line {
                height: 25rem;
              }
              .scroller {
                height: 10.25rem;
              }
            }
        }
        
    }
  }
}
