#header {
  display: flex;
  z-index: 3;
  height: calc(#{$p-height} / 2);
  width: 100vw;
  background-image: linear-gradient(0deg,rgba(17, 20, 39, 0) -1%,#111427 20%);
  position: fixed;
  @media only screen and (min-width: 768px) {
    & {
      height: calc(#{$p-height} / 2);
    }
  }
  @media only screen and (min-width: 1200px) {
    & {
      height: calc(#{$p-height});
    }
  }
  .logo {
    position: fixed;
    top: 2.1875rem;
    left: 5.625rem;
    z-index: 3;
    height: 2.5rem;
    display: flex;
    align-items: center;
    img {
      height: 1.875rem;
    }
    @media only screen and (min-width: 1200px) {
      & {
        top: 3.375rem;
        left: 9.25rem;
      }
    }
    @media only screen and (max-width: 1024px) {
      & {
        top: 1.5625rem;
        left: 3rem;
        img {
          height: 1.875rem;
        }
      }
    }
    @media only screen and (max-width: 767px) {
      & {
        top: 1.5625rem;
        left: 1.15625rem;
      }
    }
  }
  .social {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 2.1875rem;
    right: 5.625rem;
    z-index: 3;
    .contact-us {
      margin-bottom: 1.875rem;
    }
    a {
      margin-top: 1.875rem;
      img {
        transition: all 0.5s ease;
        height: 1.5rem;
        width: 1.5rem;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      & {
        flex-direction: row;
        justify-content: center;
        position: fixed;
        bottom: 0.9375rem;
        left: 0;
        right: 0;
        margin: 0 auto;
        .contact-us {
          margin-bottom: 0;
        }
        a {
          margin-top: 0;
          &:not(:last-child) {
            margin-right: 1.875rem;
          }
           img {
            height: 3.5rem;
            width: 3.5rem;
           }
        }
      }
    }
    @media only screen and (max-width: 767px) {
      & {
        bottom: 1rem;
        right: 1rem;
        .contact-us {
          margin-bottom: 0.5rem;
        }
        a {
           margin-top: 0.875rem;
           img {
            height: 2.5rem;
            width: 2.5rem;
           }
        }
      }
    }
    @media only screen and (min-width: 768px) {
      & {
        right: 2rem;
      }
    }
    @media only screen and (min-width: 1200px) {
      & {
        bottom: 3.5rem;
        right: 2rem;
      }
    }
    @media only screen and (min-width: 1920px) {
      & {
        bottom: 5.5rem;
        right: 5rem;
      }
    }
  }
  .btn.login {
    color: $light-blue;
    margin-right: 0;
    line-height: 2.25rem;
  }
  #login {
    display: none;
    @media only screen and (min-width: 1024px) {
      & {
        display: block;
      }
    }
  }
  .trial-demo {
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      & {
        display: none !important;
      }
    }
  }
  .trial-demo,
  #login {
    position: fixed;
    right: 5rem;
    z-index: 3;
    transition: all 1s ease-in-out;
    top: 2.1875rem;
    padding: 0 2rem;
    @media only screen and (max-width: 1024px) {
      & {
        top: 1.5625rem;
      }
    }
    @media only screen and (min-width: 1200px) {
      & {
        top: 3.375rem;
        right: 1.5rem;
      }
    }
    @media only screen and (min-width: 1920px) {
      & {
        right: 4.375rem;
      }
    }
    @media only screen and (orientation: landscape) and (max-width: 1024px) {
      & {
        right: 4.375rem;
      }
    }
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      & {
        top: 4.0625rem;
        right: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
  .openbtn {
    position: fixed;
    font-size: 2.5rem;
    top: 1.5625rem;
    right: 1.5625rem;
    display: none;
    z-index: 4;
    @media only screen and (max-width: 1024px) {
      & {
        display: block;
      }
    }
    @media only screen and (max-width: 767px) {
      & {
        top: 1.5625rem;
      }
    }
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      right: 1.5625rem;
    }
  }
  .menu {
    position: fixed;
    right: 1rem;
    top: 8.25rem;
    display: flex;
    flex-direction: column;
    z-index: 3;
    padding: 1.125rem 0 0;
    margin: 0 1rem;
    .closebtn {
      visibility: hidden;
      position: absolute;
      top: 1.5625rem;
      left: 1rem;
      font-size: 3.125rem;
      line-height: 1.5625rem;
      color: $orange;
      text-decoration: none;
    }
    .menu-item {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #fff;
      text-decoration: none;
      white-space: nowrap;
      font: normal normal normal 1rem/2rem Open Sans;
      letter-spacing: 0px;
      @media only screen and (max-width: 767px) {
        & {
          font: normal normal normal 2.5rem/3.5rem Open Sans;
        }
      }
      @media only screen and (orientation: landscape) and (max-width: 768px) {
        & {
          font: normal normal normal 1.75rem/2.25rem Open Sans
        }
      }
      @media only screen and (min-width: 1601px) {
        & {
          font: normal normal normal 1rem/2.5rem Open Sans;
        }
      }
      .elipse {
        width: 0.375rem;
        height: 1.25rem;
        width: 0.375rem;
        border-radius: 0;
        margin-left: 0.625rem;
        background: #414352 0% 0% no-repeat padding-box;
        transition: width 1s;
        @media only screen and (min-width: 768px) {
          & {
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
          }
        }
      }
      &.active {
        color: $orange;
        .elipse {
          background: $orange 0% 0% no-repeat padding-box;
        }
      }
    }
    &.active {
      background: $dark-blue;
      padding-left: 2rem;
      z-index: 5;
      .menu-item {
        .elipse {
          border-radius: 50%;
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
    @media only screen and (min-width: 1920px) {
      & {
        right: 4rem;
      }
    }
    @media only screen and (max-width: 1024px) {
      & {
        margin: 0;
        padding: 0;
        right: 1.5rem;
        overflow: hidden;
        font: normal normal normal 1.5rem/3rem Open Sans;
        .item-name {
          transform: translateX(285%);
          // background: $dark-blue;
        }
        
        &.active {
          .item-name {
            animation: comeIn 1s;
            animation-fill-mode: forwards;
          }
        }
        &.deactivate {
          .item-name {
            animation: comeOut 1s;
            animation-fill-mode: forwards;
          }
        }
        // width: 0;
        top: 0;
        right: 0;
        width: 6rem;
        padding-right: 2rem;
        padding-top: 6rem;
        overflow-x: hidden;
        max-width: 50%;
        transition: background 0.5s, opacity 0.5s;
        // opacity: 0;
        align-items: flex-end;
        &.active {
          top: 0;
          height: 100%;
          background: $dark-blue;
          min-width: 10.9375rem;
          width: fit-content;
          max-width: 50%;
          opacity: 0.9;
        }
        .menu-item {
          min-width: min-content;
        }
        &.active {
          .closebtn {
            visibility: visible;
          }
        }
      }
    }
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      & {
        padding-right: 1.275rem;
      }
    }
    @media only screen and (orientation: landscape) and (max-width: 767px) {
      & {
        padding-top: 5rem;
        width: 2.5rem;
      }
    }
  }
  .btn {
    height: 2.5rem;
    width: 9rem;
    max-width: 9rem;
    font: normal normal bold 1rem/2rem Open Sans;
    &.demo {
      color: $light-blue;
      letter-spacing: 0px;
    }
    &.trial {
      border-radius: 6px;
      opacity: 1;
      color: $orange;
    }
    &:last-child {
      margin-left: 0.625rem;
    }
  }
}

/* popup */
.popup {
  // position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  max-width: 80%;
  width: fit-content;
  background-color: $dark-blue;
  border: 1px solid $orange;
  color: $orange;
  font-size: 2rem;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  left: 35%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $orange transparent;
}

.trial-demo {
  .popup .popuptext {
    top: 125%;
  }
}

.article-footer {
  .popup .popuptext {
    bottom: 125%;
  }
  .popup .popuptext::after {
    top: 100%;
    left: 50%;
    border-color: $orange transparent transparent transparent;
  }
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;} 
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}
.line-wrapper {
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: -.55rem;
  z-index: 3;
}
.lines {
  display: flex;
  flex-wrap: nowrap;
  // position: fixed;
  // bottom: -0.5rem;
  // left: 0;
  z-index: 3;
  &.highlighted {
    .line {
      background: $orange;
    }
  }
  .line {
    display: inline-block;
    height: 2.625rem;
    width: 0.225rem;
    margin: 0 0.2rem;
    transform: rotate(40deg);
    background: #fff;
    opacity: 0.16;
    transition: background 1.5s ease-in-out;
  }
}
@keyframes comeIn {
  0% {
    width: auto;
    transform: translateX(285%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    width: auto;
  }
}
@keyframes comeOut {
  0% {
    transform: translateX(0);
    opacity: 1;
    width: auto;
  }
  100% {
    width: auto;
    transform: translateX(285%);
    opacity: 0;
  }
}