#signup, #signup-modal {
  position: relative;
  min-height: 100vh;
  height: max-content;
  max-height: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2rem;
  @media screen and (orientation: portrait) and (max-width: 767px) {
    & {
      padding-bottom: calc(#{$p-height} / 2);
    }
  }
  @media screen and (min-width: 768px) {
    .container {
      min-width: 34.25rem;
      width: 40%;
    }
  }
  @media screen and (min-width: 1601px) {
    & {
      .container {
        margin-top: 1.5rem;
      }
    }
  }
  .article-header {
    font: normal normal normal 1.875rem/2.5rem Open Sans;
    letter-spacing: 0.3px;
    margin: 0;
    color: #fff;
    @media screen and (min-width: 768px) {
      & {
        font: normal normal normal 1.875rem/2.5rem Open Sans;
      }
    }
    @media screen and (orientation: portrait) and (max-width: 767px) {
      & {
        font: normal normal normal 2.5rem/3.5rem Open Sans;
      }
    }
  }
  .article-text {
    font: normal normal normal 1.5rem/2rem Open Sans;
    letter-spacing: 0.16px;
    color: $light-blue;
    margin: 0;
    &.terms {
      margin: 1rem 0;
      @media screen and (min-width: 1601px) {
        & {
          margin: 2rem 0;
        }
      }
    }
    @media screen and (min-width: 768px) {
      & {
        font: normal normal normal 1rem/2.5rem Open Sans;
        margin-bottom: 0.6875rem;
      }
    }
    @media screen and (orientation: portrait) and (max-width: 767px) {
      & {
        font: normal normal normal 2rem/2.5rem Open Sans;
        margin-bottom: 0.6875rem;
      }
    }
  }
  input[type='text'],
  input[type='email'],
  input[type='password'],
  select,
  textarea {
    @extend .input-style;
  }
  select {
    padding-left: 1.75rem;
  }
  input[type='text'],
  input[type='email'],
  input[type='password'] {
    color: #fff;
  }

  label {
    padding: 0.75rem 0.75rem 0.75rem 0;
    display: inline-block;
  }

  .signup {
    font: normal normal 600 1.125rem/2.5rem Open Sans;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 3.125rem;
    width: 9.75rem;
    background: $light-blue 0% 0% no-repeat padding-box;
    // margin-top: 1.5rem;
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    // @media screen and (min-width: 768px) {
    //   margin-top: 3.125rem;
    // }
    // @media only screen and (orientation: landscape) and (max-width: 767px) {
    //   margin-top: 1rem;
    // }
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      & {
        font: normal normal 600 2.125rem/2.5rem Open Sans;
        height: 4.125rem;
        width: fit-content;
      }
    }
  }

  .signup-btn {
    display: flex;
    .italic {
      font-style: italic;
    }
  }

  
  input:focus {
    color: #fff;
  }

  select {
    color: white;
    option {
      color: white;
    }
    & option:not(:first-child) {
      color: black;
    }
    &:active {
      color: $grey;
    }
    &:active option:disabled {
      color: white;
    }
  }

  .container {
    border-radius: 5px;
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      & {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }
    }
  }

  .col-100, .col-25, .col-75 {
    position: relative;
    @media only screen and (orientation: landscape) and (max-width: 767px) {
      & {
        width: 50%;
        margin: auto;
      }
    }
  }
  // .col-25 {
  //   float: left;
  //   width: 25%;
  //   margin-top: 6px;
  // }

  .col-75 {
    white-space: nowrap;
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      & {
        width: 100%;
        float: none;
        white-space: normal;
      }
    }
  }

  // .col-75 {
  //   float: left;
  //   width: 75%;
  //   margin-top: 6px;
  // }

  /* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .col-25,
    .col-75,
    .col-100
    input[type='submit'] {
      width: 100%;
    }
  }
  ::placeholder {
    color: white;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: white;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: white;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media only screen and (orientation: portrait) and (max-width: 1025px) {
      flex-direction: column-reverse;
      align-items: center;
    }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      flex-direction: column-reverse;
      align-items: center;
    }
    .terms {
      text-align: center;
    }
    .footer-link {
      margin: 0 1rem;
      white-space: nowrap;
      font: normal normal normal 1rem/2.5rem Open Sans;
      &:only-child {
        margin: 0;
      }
      @media only screen and (orientation: portrait) and (max-width: 767px) {
        & {
          margin: 0 0.5rem;
          font: normal normal normal 1.375rem/2.5rem Open Sans;
        }
      }
      a {
        color: white;
        &.visited {
          color: white;
        }
      }
    }
  }
}
#signup {
  .footer {
    margin-top: 2rem;
  }
  .wrapper-dropdown-2 {
    /* Size and position */
    display: flex;
    position: relative; /* Enable absolute positioning for children and pseudo elements */
    cursor: pointer;
    outline: none;
    // overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 1rem;
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      & {
        padding-top: 1rem;
      }
    }
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      & {
        padding-top: 2rem;
      }
    }
    padding-right: 4rem;
    .activeSelect {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .overlay {
      display: none;
      opacity: 0.5;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #000;
    }
    &.active {
      .overlay {
        @media only screen and (max-width: 812px) {
          & {
            display: block;
          }
        }
      }
    }
    &:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -3px;
        border-width: 6px 6px 0 6px;
        border-style: solid;
        border-color: grey transparent;
    }
    .dropdown {
      /* Size & position */
        position: absolute;
        top: 100%;
        left: -5px;
        right: 0px;
    
        /* Styles */
        background: $dark-blue;
        transition: all 0.3s ease-out;
        list-style: none;
    
        /* Hiding */
        opacity: 0;
        pointer-events: none;
        z-index: 99999;
        @media only screen and (max-width: 812px) {
          & {
            position: fixed;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 15px;
          }
        }
        .select {
          display: flex;
          position: relative;
          padding: 10px 0;
          padding-left: 2rem;
          color: #fff;
          transition: all 0.5s ease-out;
          font: normal normal 300 1rem/1.325rem Open Sans;
          @media only screen and (max-width: 812px) {
            & {
              font: normal normal 300 2rem/2.625rem Open Sans;
              padding-left: 5rem;
            }
          }
          &:hover,&.active {
            color: $light-blue;
            &:before {
              opacity: 1;
              color: $orange;
            }
          }
        }
        .select:before {
          margin-right: 0.25rem;
          cursor: pointer;
          font-size: 3rem;
          line-height: 2.5rem;
          position: absolute;
          left: 0.5rem;
          top: 0;
          bottom: 0;
          height: 2.375rem;
          margin-top: auto;
          margin-bottom: auto;
          content: '•';
          text-align: center;
          opacity: 0.25;
          color: white;
          transition: color 0.25s ease-in-out, opacity 0.25s ease-in-out;
          -webkit-font-smoothing: antialiased;
          @media only screen and (max-width: 812px) {
            & {
              font-size: 8rem;
              height: 2.5rem;
              left: 1.5rem;
            }
          }
          &.active {
            color: $orange;
          }
        }
    }
  }
.wrapper-dropdown-2.active:after {
    border-width: 0 6px 6px 6px;
}

.wrapper-dropdown-2.active .dropdown {
    opacity: 1;
    pointer-events: auto;
}
}
#signup-modal.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 4;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #111427;
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  .col-25, .col-75 {
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      & {
        width: 100%;
        float: none;
        white-space: normal;
      }
    }
  }
  .logo {
    padding: 1rem;
    img {
      height: 1.875rem;
    }
  }
  .container {
    justify-content: space-around;
  }
  /* Modal Content */
  .modal-content {
    overflow: auto;
    position: fixed;
    bottom: 0;
    background-color: $dark-blue;
    background-size: 500%;
    background-image: url(../img/main-image-min.png);
    background-position-x: -17rem;
    background-position-y: -100rem;
    width: 100%;
    height: 100%;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s
  }
  
  /* The Close Button */
  .close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal-header {
    padding: 0.125rem 1rem;
    font-size: 4rem;
    color: white;
  }
  
  .modal-body {
    padding: 0.125rem 1rem;
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      & {
        padding: 2rem 2rem;
      }
    }
  }
  
  .modal-footer {
    color: white;
  }
  
  /* Add Animation */
  @-webkit-keyframes slideIn {
    from {bottom: -300px; opacity: 0} 
    to {bottom: 0; opacity: 1}
  }
  
  @keyframes slideIn {
    from {bottom: -300px; opacity: 0}
    to {bottom: 0; opacity: 1}
  }
  
  @-webkit-keyframes fadeIn {
    from {opacity: 0} 
    to {opacity: 1}
  }
  
  @keyframes fadeIn {
    from {opacity: 0} 
    to {opacity: 1}
  }
}