.animate-box-wrap {
  display: flex;
  align-items: baseline;
  position: relative;
  width: 100%;
  .dot {
    position: absolute;
    left: 32rem;
    bottom: 1.75rem;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: orange;
    animation: dotInit 2s ease;
    animation-delay: 0.1s;
  }
  &.animate-start {
    .dot {
      transform: translateX(-4rem);
      animation: dot 11.2s ease;
    }
    span {
      &.first {
        animation: first 3s ease;
        animation-fill-mode: forwards;
      }
      &.second {
        animation: second 3s ease;
        animation-delay: 4s;
        animation-fill-mode: forwards;
      }
      &.third {
        animation: third 4s;
        animation-delay: 7s;
        animation-fill-mode: forwards;
      }
    }
  }
}
.animate-box {
  display: inline-flex;
  overflow: hidden;
  span {
    display: block;
    opacity: 0;
    left: 0;
    top: 0;
    color: #fff;
    font: normal normal bold 6.75rem/8.9375rem Open Sans;
    letter-spacing: 0.175rem;
    overflow: hidden;
    width: 0;
    // &.third {
    //   animation: thirdInit 1.8s ease;
    //   animation-delay: 0.1s;
    // }
  }
}
@keyframes dotInit {
  0% {
    transform: translateX(4.1em);
  }
  60% {
    transform: translateX(4.1em);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes thirdInit {
  0% {
    transform: translateX(0);
    opacity: 1;
    width: auto;
  }
  60% {
    transform: translateX(0);
    opacity: 1;
    width: auto;
  }
  99% {
    transform: translateX(-100%);
    opacity: 1;
    width: auto;
  }
  100% {
    transform: translateX(-100%);
    opacity: 1;
    width: 0;
  }
}
@keyframes first {
  0% {
    opacity: 0;
    width: auto;
    transform: translateX(0) translateY(100%);
  }
  40% {
    transform: translateX(0) translateY(0);
    opacity: 1;
    width: auto;
  }
  65% {
    transform: translateX(0) translateY(0);
    opacity: 1;
    width: auto;
  }
  72% {
    transform: translateX(-100%);
    opacity: 1;
    width: auto;
  }
  80% {
    transform: translateX(-75%);
    opacity: 1;
  }
  86% {
    transform: translateX(-100%);
    opacity: 1;
    width: auto;
  }
  92% {
    transform: translateX(-92%);
    opacity: 1;
    width: auto;
  }
  99% {
    transform: translateX(-100%);
    opacity: 1;
    width: auto;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
    width: 0;
  }
}
@keyframes second {
  0% {
    width: auto;
    transform: translateY(100%);
    opacity: 0;
  }
  15% {
    transform: translateY(-10%);
    opacity: 1;
    width: auto;
  }
  20% {
    transform: translateY(0);
    opacity: 1;
    width: auto;
  }
  25% {
    transform: translateY(-5%);
    opacity: 1;
    width: auto;
  }
  30% {
    transform: translateY(0);
    opacity: 1;
    width: auto;
  }
  85% {
    transform: translateY(0);
    opacity: 1;
    width: auto;
  }
  99% {
    transform: translateY(-100%);
    opacity: 0;
    width: auto;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
    width: 0;
  }
}
@keyframes third {
  0% {
    width: auto;
    transform: translateX(100%);
    opacity: 0;
  }
  20% {
    transform: translateX(0);
    opacity: 1;
    width: auto;
  }
  80% {
    transform: translateX(0);
    opacity: 1;
    width: auto;
  }
  99% {
    transform: translateX(-100%);
    opacity: 1;
    width: auto;
  }
  100% {
    transform: translateX(-100%);
    opacity: 1;
    width: 0;
  }
}
@keyframes dot {
  0% {
    transform: translateX(-4rem);
  }
  17.5% {
    transform: translateX(-4rem);
  }
  19.3% {
    transform: translateX(-31rem);
  }
  21.2% {
    transform: translateX(-24rem);
  }
  23.6% {
    transform: translateX(-30rem);
  }
  24.7% {
    transform: translateX(-29rem);
  }
  29% {
    transform: translateX(-32rem);
  }
  30% {
    transform: translateX(10.6rem);
  }
  31% {
    transform: translateX(5rem);
  }
  32% {
    transform: translateX(10.6rem);
  }
  33% {
    transform: translateX(9rem);
  }
  34% {
    transform: translateX(10.6rem);
  }
  60% {
    transform: translateX(10.6rem);
  }
  66.666% {
    transform: translateX(-20.7rem);
  }
  95% {
    transform: translateX(-20.7rem);
  }
  100% {
    transform: translateX(-4rem);
  }
}
