
.content.sections {
    padding-top: 0;
    &:not(.last) {
        padding-bottom: 0;
    }
    &#management {
        .description {
            padding-top: $p-height;
        }
    }
    .description {
        display: flex;
        padding: 6rem 0;
        justify-content: center;
        position: relative;
        align-items: center;
        @media only screen and (min-width: 1921px) {
            & {
                max-width: 70vw;
                margin: 0 auto;
            }
        }
        &.analysis {
            @media only screen and (orientation: portrait) and (max-width: 768px) {
                & {
                    flex-direction: column;
                }
            }
        }
        &.dashboards {
            .image {
                margin-top: 1.1rem;
            }
        }
        &.row-reverse {
            flex-direction: row-reverse;
            justify-content: center;
            @media only screen and (min-width: 1024px) {
                & {
                    justify-content: flex-end;
                }
            }
            .text {
                .transparent-img {
                    right: 26%;
                    left: auto;
                }
            }
            .image {
                margin-right: 5rem;
            }
        }
        &.colunm {
            flex-direction: column;
        }
        .text {
            flex: 0 1 auto;
            .transparent-img {
                position: absolute;
                max-height: 31.25rem;
                height: 31.25rem;
                // top: -0.875rem;
                top: 0;
                left: 18%;
                &.analysis {
                    top: 6rem;
                    left: 1%;
                }
            }
            .article-header {
                text-align: left;
                font: normal normal bold 2.25rem/1.875rem Open Sans;   
                margin-bottom: 2rem;
            }
            .list {
                margin-left: 2rem;
                li {
                    padding-left: 1.5rem;
                }
                li:before {
                    font-size: 200%;
                    color: $orange;
                }
                .article-text {
                    color: #fff;
                    margin-bottom: 0;
                    font: normal normal normal 1.08rem/2rem Open Sans;
                }
                margin-bottom: 1rem;
            }
            .article-text {
                color: $light-blue;
                font: normal normal normal 1.08rem/1.875rem Open Sans;
                margin-bottom: 0;
            }
        }
        &:not(.row-reverse) {
            .image {
                margin-left: 6rem;
            }
        }
        .image {
            width: fit-content;
            position: relative;
            flex: 0 1 auto;
            &.analysis {
                margin-top: 3.875rem;
                flex: 0 1 43%;
                margin-left: 2rem;
                @media only screen and (orientation: portrait) and (max-width: 768px) {
                    & {
                        align-self: flex-end;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
            img {
                // max-width: 30rem;
                max-width: 100%;
                max-height: 21rem;
                height: 21rem;
                &.analysis {
                    max-height: 11.875rem;
                    max-width: 36.75rem;
                    width: 99%;
                }
                &.optimization {
                    max-height: 20rem;
                    height: 20rem;
                }
            }
            .colored {
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}