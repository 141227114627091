@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Regular.ttf");
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Light.ttf");
    font-weight: 300;
}
@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-SemiBold.ttf");
    font-weight: 500;
}