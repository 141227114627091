#pricing-1 {
  display: flex;
  @media only screen and (orientation: portrait) and (max-width: 768px) {
    & {
      justify-content: center;
    }
  }
  .pricing-h1 {
    font: normal normal normal 1.875rem/2.5rem Open Sans;
    letter-spacing: 0.3px;
    margin-right: 1rem;
    @media only screen and (orientation: portrait) and (max-width: 1600px) {
      & {
        padding-top: 1.5rem;
        margin-bottom: 2rem;
      }
    }
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      // & {
      //   display: none;
      // }
    }
  }
  @media only screen and (min-width: 1601px) {
    .pricing-h1,
    .pricing-items {
      padding-top: 1.5rem;
    }
    .pricing-h1 {
      padding-left: 1rem;
    }
  }
  @media only screen and (max-width: 1601px) {
    & {
      display: flex;
      flex-direction: column;
    }
  }
  .pricing-items {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .arrow {
      display: none;
    }
    .article-header {
      margin-bottom: 0;
    }
    @media only screen and (min-width: 1601px) {
      & {
        align-items: baseline;
      }
    }
    @media only screen and (orientation: portrait) and (min-width: 768px) and (max-width: 768px) {
      & {
        width: 90%;
      }
    }
    @media only screen and (orientation: landscape) and (max-width: 767px) {
      & {
        align-items: baseline;
        overflow: hidden;
        .details {
          .btn {
            height: 1.875rem;
            font: normal normal bold 1rem/1rem Open Sans;
            margin-top: 0.25rem;
          }
        }
      }
    }
    @media only screen and (orientation: portrait) and (max-width: 768px) {
      & {
        flex-direction: row;
        justify-content: flex-start;
        height: auto;
        overflow: hidden;
        .pricing-h1 {
          margin-left: 1rem;
          font: normal normal normal 2.875rem/2.5rem Open Sans;
        }
        .blink {
          display: block;
          -webkit-animation: blink 2s linear infinite;
          -moz-animation: blink 2s linear infinite;
          animation: blink 2s linear infinite;
        }
        .details {
          width: 100%;
          flex: 0 0 100%;
          position: relative;
          margin-right: 0;
          margin: 0 1%;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      & {
        overflow: hidden;
        .details {
          .category-h1 {
            font: normal normal bold 2.5rem/2.8125rem Open Sans;
          }
          .pricing-category {
            .month {
              height: 3rem;
              max-height: 3rem;
            }
            .article-text.year {
              font: normal normal normal 1.75rem/1.75rem Open Sans;
            }
            .article-text.per-month {
              font: normal normal normal 2.25rem/2rem Open Sans;
            }
            .article-text.sum {
              font: normal normal bold 2.5rem/2.5rem Open Sans;
            }
          }
          .btn {
            height: 4rem;
            font: normal normal bold 2rem/2.1875rem Open Sans;
            margin-top: 0.25rem;
          }
          .pricing-card {
            .pricing-header {
              // margin: 0.25rem 0 0 0;
              .article-header {
                font: normal normal 600 2rem/2.875rem Open Sans;
              }
            }
            .article-header {
              font: normal normal 600 2rem/2.875rem Open Sans;
            }
            .pricing-options {
              .article-text {
                font: normal normal normal 1.625rem/2rem Open Sans;
                height: 3rem;
              }
            }
            .extra {
              font: normal normal normal 1.875rem/2.1875rem Open Sans;
            }
          }
        }
      }
    }
  }
  .details {
    flex: 0 0 22.5%;
    padding-bottom: 3rem;
    padding: 0;
    border-radius: 8px;
    text-align: center;
    height: fit-content;
    max-width: 18.75rem;
    min-width: 13.4375rem;
    padding: 1rem 0.5rem 1.5rem 0.5rem;
    margin-left: 2.5%;
    @media only screen and (orientation: portrait) and (max-width: 767px) {
      & {
        max-width: 28.75rem;
      }
    }
    @media only screen and (min-width: 1200px) {
      & {
        min-width: 17.875rem;
      }
    }
    @media only screen and (min-width: 768px) {
      & {
        min-height: 33.4375rem;
        min-width: 15.4375rem;
        padding: 1rem 0.5rem 1.5rem 0.5rem;
      }
    }
    background: rgba(23, 27, 48, 0.8) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    &.pro {
      background: rgba(14, 29, 53, 0.8) 0% 0% no-repeat padding-box;
      h1 {
        color: $light-blue;
      }
      .btn {
        background: $orange 0% 0% no-repeat padding-box;
      }
    }
    .btn {
      width: 100%;
      height: 3.125rem;
      text-align: center;
      font: normal normal bold 1.25rem/2.1875rem Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      border: none;
      background: $light-blue 0% 0% no-repeat padding-box;
      border-radius: 4px;
    }
    @media only screen and (min-width: 1281px) {
      & {
        padding: 1rem 0.875rem 1.5rem 0.875rem;
      }
    }
    .category-h1 {
      font: normal normal bold 1.875rem/2.8125rem Open Sans;
      letter-spacing: 0px;
    }
    .pricing-category {
      display: flex;
      flex-direction: column;
      .category {
        flex: 0 0 2.5rem;
        color: $light-blue;
      }
      .year {
        flex: 0 0 2rem;
        @media only screen and (min-width: 1281px) {
          & {
            flex: 0 0 2.5rem;
          }
        }
        @media only screen and (orientation: landscape) and (max-width: 767px) {
          & {
            flex: 0 0 1.5rem;
          }
        }
      }
      h1 {
        font: normal normal bold 1.875rem/2.8125rem Open Sans;
        letter-spacing: 0px;
      }
      .month {
        display: flex;
        justify-content: center;
        align-items: baseline;
        height: 1.875rem;
        @media only screen and (min-width: 1281px) {
          & {
            height: 2.8125rem;
          }
        }
      }
      .article-text.year {
        font: normal normal normal 1.125rem/1.5rem Open Sans;
        letter-spacing: 0px;
        margin: 0;
        color: $light-blue;
        @media only screen and (min-width: 1281px) {
          & {
            font: normal normal normal 1.125rem/2.5rem Open Sans;
          }
        }
      }
      .article-text.per-month {
        font: normal normal normal 1.25rem/2rem Open Sans;
        letter-spacing: 0px;
        margin: 0;
        @media only screen and (min-width: 1281px) {
          & {
            font: normal normal normal 1.25rem/2.8125rem Open Sans;
          }
        }
      }
      .article-text.sum {
        font: normal normal bold 2rem/2rem Open Sans;
        letter-spacing: 0px;
        color: $orange;
        margin: 0;
        @media only screen and (min-width: 1281px) {
          & {
            font: normal normal bold 2.25rem/2.8125rem Open Sans;
          }
        }
      }
      @media only screen and (min-width: 1281px) {
        & {
          h1 {
            font: normal normal bold 2.125rem/2.5rem Open Sans;
            letter-spacing: 0.34px;
          }
          .article-text {
            font: normal normal normal 1.5rem/2.5rem Open Sans;
            letter-spacing: 0.24px;
            margin: 0;
          }
        }
      }
    }
    .pricing-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .pricing-header {
        margin: 0.5rem 0 0 0;
        width: 100%;
        .article-header {
          text-align: left;
          font: normal normal 600 1.375rem/1.875rem Open Sans;
          letter-spacing: 0px;
          color: $light-blue;
          @media only screen and (orientation: landscape) and (max-width: 767px) {
            & {
              font: normal normal 600 1.375rem/1.5rem Open Sans;
            }
          }
        }
        @media only screen and (min-width: 1601px) {
          & {
            margin: 1rem 0 0 0;
            .article-header {
              font: normal normal 600 1.375rem/2.5rem Open Sans;
            }
            h2 {
              font: normal normal normal 2.75rem/2.5rem Open Sans;
              letter-spacing: 0.44px;
              color: #fff;
              margin: 0;
            }
            .article-text {
              font: normal normal normal 1.125rem/2.5rem Open Sans;
              letter-spacing: 0.18px;
              margin: 0;
            }
          }
        }
      }
      .pricing-options {
        width: 100%;
        .article-text {
          height: 1.5rem;
          @media only screen and (min-width: 1601px) {
            & {
              height: 2rem;
            }
          }
          @media only screen and (max-width: 1280px) {
            & {
              height: 1.75rem;
            }
          }
          @media only screen and (orientation: landscape) and (max-width: 767px) {
            & {
              height: 1.125rem;
            }
          }
        }
      }
      .article-text {
        text-align: left;
        font: normal normal normal 1rem/1.875rem Open Sans;
        letter-spacing: 0px;
        margin-bottom: 0;
        line-height: 1.875rem;
        @media only screen and (min-width: 1281px) {
          & {
            font: normal normal normal 1.125rem/1.875rem Open Sans;
            line-height: 1.875rem;
          }
        }
        @media only screen and (min-width: 1920px) {
          & {
            line-height: 2rem;
          }
        }
      }
      .disabled {
        color: #ffffff33;
      }
      .highlight {
        color: $orange;
      }
      .extra {
        font: normal normal normal 0.875rem/2.1875rem Open Sans;
        letter-spacing: 0px;
        color: #707070;
        @media only screen and (orientation: landscape) and (max-width: 767px) {
          & {
            font: normal normal normal 0.875rem/1.1875rem Open Sans;
          }
        }
      }
      button {
        height: 50px;
        background-color: $light-blue;
        color: #fff;
        border: none;
        margin: 0;
        font: normal normal 600 1rem/1.5rem Open Sans;
        letter-spacing: 0.22px;
        @media only screen and (min-width: 1281px) {
          & {
            font: normal normal 600 1.375rem/1.875rem Open Sans;
            letter-spacing: 0.22px;
          }
        }
      }
      .pricing-description {
        margin: $py 0;
        ol {
          text-align: left;
          list-style: inside;
          list-style-type: decimal;
        }
        .article-text {
          font: normal normal 300 1rem/1.5rem Open Sans;
          letter-spacing: 0.2px;
          margin: 0;
          @media only screen and (min-width: 1281px) {
            & {
              font: normal normal 300 1.25rem/1.875rem Open Sans;
              letter-spacing: 0.2px;
            }
          }
        }
      }
    }
  }
}
