.wrapper-404 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-position-x: 5%;
    background-position-y: 7%;
    background-size: auto;
    background-repeat: no-repeat;
    background-attachment: initial;
    background-origin: padding-box;
    background-clip: padding-box;
    background-color: transparent;
    background-image: url("../img/main-image-min.png");
    .img-404 {
        max-width: 11.9375rem;
    }
    .article-header {
        color: white;
        margin-top: 4rem;
    }
}