#slider-info {
  position: relative;
  @media only screen and (orientation: landscape) {
    & {
      // padding-left: calc(#{$p-height} / 2);
      padding-bottom: 1rem;
    }
  }
  .description {
    display: flex;
    padding-top: 1rem;
    height: 100%;
    height: 85%;
    position: relative;
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      & {
        height: 70%;
      }
    }
    .schema {
      flex: 0 0 52.5%;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .background {
        opacity: 0.5;
      }
      .part-image {
        position: absolute;
        top: 0;
        left: 0;
      }
      .slide-part {
        display: none;
        // animation: fadeinout 10s linear forwards;
      }
    }
    .schema-details {
      flex: 0 0 47.5%;
      display: flex;
      // padding: 2.5rem 5rem 1rem 1rem;
      align-items: flex-start;
      position: relative;
      @media only screen and (orientation: portrait) and (max-width: 812px) {
        & {
          flex: 1 0 47.5%;
        }
      }
      .line {
        width: 2px;
        height: calc(100% + 1rem);
        border-right: 2px dashed grey;
        margin-right: 1rem;
      }
      .inner-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0 3rem 1rem 2rem;
        position: absolute;
        &.active {
          position: initial;
        }
        @media screen and (min-width: 1200px) {
          & {
            padding: 0 7rem 1rem 2rem;
          }
        }
        .article-header {
          color: $orange;
          font: normal normal 600 1.25rem/1.375rem Open Sans;
          margin-bottom: 1rem;
        }
        .inner-img {
          flex: 0 1 auto;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
        .inner-content {
          flex: 0 0 auto;
          max-height: 43%;
          height: fit-content;
          display: flex;
          padding-top: 1rem;
          .article-text {
            font: normal normal normal 1.125rem/1.875rem Open Sans;
            letter-spacing: 0px;
            // white-space: nowrap;
            margin-bottom: 0;
          }
          .v-line {
            background-color: white;
            width: 2px;
            height: initial;
            margin-right: 1rem;
          }
        }
      }
      .slide {
        display: none;
        animation: fadeinout 10s linear forwards;
      }
    }
    .dots-container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -5rem;
      flex: 0 0 auto;
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
      .dots {
        &:before {
          margin-right: 0.25rem;
          cursor: pointer;
          font-size: 4rem;
          line-height: 1.25rem;
          width: 20px;
          height: 20px;
          content: '•';
          text-align: center;
          opacity: 0.25;
          color: white;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        &.active {
          &:before {
            color: $orange;
            opacity: 1;
          }
        }
      }
    }
  }

  @media screen and (orientation: portrait) and (max-width: 812px) {
    & {
      .description {
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        padding-left: 2rem;
        width: 85%;
        margin: auto;
        .schema {
            flex: 0 1 40%;
        }
        .schema-details {
          margin-top: 1.5rem;
          padding: 1rem 1rem 0 1rem;
          padding: 0;
          img {
            width: 85%;
          }
          .inner-details {
            padding: 0;
          }
          .inner-img {
            flex: 0 0 50%;
            height: auto;
          }
          .inner-content {
            margin-bottom: 1rem;
          }
        }
        .line {
          display: none;
        }
      }
    }
  }
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  7% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  7% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
}
