#pricing {
    display: flex;
    flex-direction: column;
    .pricing-header {
        .pricing-h1 {
            font: normal normal normal 1.875rem/2.5rem Open Sans;
            letter-spacing: 0.01875rem;
        }
    }
    .details {
        display: flex;
        flex-direction: column;
        min-width: 18.125rem;
        padding: 0.25rem 0.9375rem 1.375rem;
        .per-month, .plan-header, .plan-description {
            white-space: pre-line;
        }
    }
    .pricing-body {
        display: flex;
        .details {
            display: flex;
            flex-direction: column;
            min-width: 18.125rem;
            padding: 0.25rem 0.9375rem 1.375rem;
            border-radius: 0.375rem;
            .description-header {
                font: normal normal 600 1.875rem/2.5rem Open Sans;
                letter-spacing: 0rem;
            }
            .article-header {
                font: normal normal 600 1.375rem/2.5rem Open Sans;
                letter-spacing: 0rem;
                margin-bottom: 0;
            }
            .article-text {
                font: normal normal normal 1.125rem/1.875rem Open Sans;
                letter-spacing: 0rem;
                margin-bottom: 0;
                min-height: 1.8125rem;
            }
            &:not(.description) {
                text-align: center;
                background: rgba(23, 27, 48, 0.8) 0% 0% no-repeat padding-box;
                border-radius: 0.375rem;
                // opacity: 0.8;
                margin-left: 1.5625rem;
            }
            &.pro {
                color: $orange;
                
                background: rgba(14, 29, 53, 0.8) 0% 0% no-repeat padding-box;
                .article-text {
                    color: $orange;
                }
                .header {

                    .btn {
                        background: $orange 0% 0% no-repeat padding-box;
                    }
                }
            }
            &.description {
                padding: 0.25rem 0 1.375rem;
                .header {
                    display: flex;
                    flex-direction: column;
                    .description-header {
                        margin: auto 0 0 0;
                    }
                }
                .article-text {
                    display: flex;
                    align-items: center;
                    .info {
                        width: 1.25rem;
                        height: 1.3125rem;
                        margin-left: 0.25rem;
                        display: inline-block;
                        background: transparent url('../img/ic_info_outline_24px.svg') 0% 0% / 1.25rem 1.25rem no-repeat padding-box;
                    }
                }
            }
            .header {
                flex: 0 0 9rem;
                .main {
                    height: 5.625rem;

                    position: relative; 
                }
                .plan-header {
                    font: normal normal bold 1.875rem/2.8125rem Open Sans;
                    letter-spacing: 0rem;
                    text-align: center;
                    .price {
                        font: normal normal bold 2.25rem/2.8125rem Open Sans;
                        letter-spacing: 0rem;
                    }
                }
                .per-month {
                    right: 0;
                    bottom: 0.175rem;
                    font: normal normal normal 1rem/1rem Open Sans;
                    letter-spacing: 0rem;
                    position: absolute;
                    text-align: left;
                }
                .btn {
                    margin-top: 0.5rem;
                    width: 100%;
                    height: 3.125rem;
                    text-align: center;
                    font: normal normal bold 1.25rem/2.1875rem Open Sans;
                    letter-spacing: 0rem;
                    color: #ffffff;
                    border: none;
                    background: $light-blue 0% 0% no-repeat padding-box;
                    border-radius: 0.25rem;
                }
            }
            .functionality {
                flex: 0 0 13rem;
                margin-top: 0.9375rem;
            }
            .workspaces {
                flex: 0 0 6rem;
                margin-top: 1.375rem;
            }
            .usage {
                flex: 0 0 6rem;
                margin-top: 1.75rem;
            }
        }
    }
    .details.description, .details.header {
        min-width: 20.5rem;
    }
    .pricing-footer {
        display: flex;
        margin-top: 1.625rem;
        .cloud-header {
            font: normal normal 600 1.5rem/2.5rem Open Sans;
            letter-spacing: 0rem;
        }
        .plan-header {
            font: normal normal bold 1.625rem/2.25rem Open Sans;
            letter-spacing: 0rem;
            .price {
                color: $orange;
            }
        }
        .details.header {
            padding: 0.25rem 0 1.375rem;
        }
        .details.cloud {
            display: flex;
            flex-direction: row;
            margin-left: 1.5625rem;
            flex: 0 0 calc(18.125rem * 3 + 1.5625rem * 2);
            justify-content: space-between;
            text-align: center;
            border-radius: 0.375rem;
            background: rgba(38, 38, 38, 0.8) 0% 0% no-repeat padding-box;
            .package.cloud {
                .plan-header {
                    font: normal normal bold 1.625rem/2.8125rem Open Sans;
                    letter-spacing: 0rem;
                }
                .plan-description {
                    font: normal normal 600 1.25rem/1.75rem Open Sans;
                    color: $orange;
                }
            }
            .package {
                .description {
                    .article-text {
                        font: normal normal normal 1.125rem/1.5rem Open Sans;
                        letter-spacing: 0rem;
                        margin-bottom: 0;
                        .size {
                            color: $orange;
                        }
                    }
                }
            }
        }
    }
}